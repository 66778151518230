<template>
  <div><strong>{{ value.display_name }}</strong><br>
    <template v-if="value.phone"><i class="fa fa-phone"></i> {{ value.phone }}<br></template>
    <template v-if="value.mobile"><i class="fa fa-mobile"></i> {{ value.mobile }}<br></template>
    <template v-if="value.email"><i class="fa fa-envelope"></i> {{ value.email }}<br></template>
  </div>
</template>

<script>
export default {
  name: 'ContactSearchRenderComponent',
  props: ['value']
}
</script>

<style scoped>

</style>
